import React, { useEffect, useContext } from 'react'
import Register from '../../Auth/Register/Register'
import Layout from '../../components/layout'
import { gsap } from "gsap";
import { Link } from 'gatsby';
import { AuthContext } from '../../firebase'
import {navigate } from 'gatsby'
import Seo from '../../components/seo'

const SignupDoctor = () => {

  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit

  useEffect(() => {
    user && navigate("/app/dashboard")
  }, [])
  

  let content = null;

  useEffect(() => {
    
      gsap.from(
        content.children,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
      )
  }, [content]);
  return (
    <Layout>
      <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti"/>
      <div className="sign-contain">
        <div className="sign-element mycard">
          <h5 className="center color-1">Vous &ecirc;tes m&eacute;decin?</h5>
          <h3 className="center color-4">Inscrivez-vous ici</h3>
          <Register isDoctor={true} ref={e => (content = e)}/>
          <p className="sign-alt color-1 center">Vous avez d&eacute;j&agrave; un compte? <Link className="sign-alt-link" to="/doctor">Connectez-vous ici!</Link></p>
        </div>
      </div>
    </Layout>
  );
};

export default SignupDoctor;